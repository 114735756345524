import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import './style.css';
import { branchGetAll, changeBranch, branchGetAllByUser } from '../../../modules/category/branch/service';
import { updateLanguageRequest } from '../../../modules/core/appSetting/service';
import Field from './Field.js';
import Item from './Item.js';
import { connect } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import Transformer from '../../../utils/Transformer';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { changeWarehouse } from '../../../modules/config/usercustom/service';

class Branch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchs: null,
            defaultValue: null,
            currentLanguage: this.props.currentLanguage,
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { userSettings, currentLanguage, listBranch } = nextProps;
        const branch = userSettings.find((x) => x.key === 'BranchId');
        if (branch) {
            this.setState({
                defaultValue: branch.value,
            });
        }

        if (currentLanguage !== this.state.currentLanguage) {
            this.setState({
                currentLanguage,
                branchs: this.handleLanguage(currentLanguage),
            });
        }

        if (listBranch !== this.props.listBranch) {
            this.setState({
                branchs: new ArrayStore({
                    data: listBranch.map((item) => ({
                        id: item.id,
                        name: Transformer.IsJsonString(item.name)
                            ? JSON.parse(item.name)[this.props.currentLanguage]
                            : item.name,
                    })),
                    key: 'id',
                }),
            });
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { userSettings, currentUser, languages } = this.props;

        let languageId = userSettings.find((x) => x.key === 'LanguageId');
        let currentLanguage = null;

        if (!languageId) {
            languageId = languages.find((x) => x.isDefault);
            if (!languageId) {
                languageId = languages[0];
            }

            currentLanguage = languageId.languageId;
            this.props.changeLanguage(languageId.languageId);
        } else {
            currentLanguage = languageId.value;
        }

        if (currentUser.isAdmin) {
            this.props.branchGetAll().then(() => {
                let branch = userSettings.find((x) => x.key === 'BranchId');

                if (!branch || branch.value === 'null') {
                    branch = this.props.listBranch.find((x) => x.isDefault);
                    if (!branch) {
                        branch = this.props.listBranch[0];
                    }
                    this.props.changeBranch(branch.id);

                    this.setState({
                        branchs: this.handleLanguage(currentLanguage),
                        defaultValue: branch.id,
                    });
                } else {
                    this.setState({
                        branchs: this.handleLanguage(currentLanguage),
                        defaultValue: branch.value,
                    });
                }
            });
        } else {
            this.props.branchGetAllByUser().then(() => {
                let branch = userSettings.find((x) => x.key === 'BranchId');
                if (!branch || !branch.value) {
                    branch = this.props.listBranch.find((x) => x.isDefault);
                    if (!branch) {
                        branch = this.props.listBranch[0];
                    }
                    this.props.changeBranch(branch.id);

                    let languageId = userSettings.find((x) => x.key === 'LanguageId');
                    if (!languageId) {
                        languageId = languages.find((x) => x.isDefault);
                        if (!languageId) {
                            languageId = languages[0];
                        }

                        this.props.changeLanguage(currentLanguage);
                    }

                    this.setState({
                        branchs: this.handleLanguage(currentLanguage),
                        defaultValue: branch.id,
                    });
                } else {
                    this.setState({
                        branchs: this.handleLanguage(currentLanguage),
                        defaultValue: branch.value,
                    });
                }
            });
        }
    }

    handleLanguage(currentLanguage) {
        return new ArrayStore({
            data: this.props.listBranch.map((item) => ({
                id: item.id,
                name: Transformer.IsJsonString(item.name)
                    ? Transformer.convertJSONString(item.name, currentLanguage)
                    : item.name,
            })),
            key: 'id',
        });
    }

    onValueChanged(e) {
        this.setState({
            defaultValue: e.value,
        });
        this.props
            .changeBranch(e.value)
            .then((data) => {})
            .catch((error) => {
                ToastsStore.error(error.response.data.message);
            });

        // this.props.changeWarehouse(e.value).then(() => {});
    }

    render() {
        const { branchs, defaultValue } = this.state;

        return (
            <div>
                {defaultValue ? (
                    <SelectBox
                        className="branch-dropdown"
                        searchEnabled={true}
                        id={'custom-templates'}
                        dataSource={branchs}
                        displayExpr={'name'}
                        width={310}
                        height={'auto'}
                        valueExpr={'id'}
                        fieldRender={Field}
                        value={defaultValue}
                        onValueChanged={this.onValueChanged}
                        itemRender={Item}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const appSettings = state.appSettings.data;
    const currentLanguage = _.find(appSettings.userSettings, (item) => item.key === 'LanguageId');

    return {
        listBranch: state.branch.suggestion,
        languages: appSettings.languages,
        currentLanguage: currentLanguage.value,
        userSettings: appSettings.userSettings,
        currentUser: appSettings.currentUser,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        branchGetAll: () => {
            return dispatch(branchGetAll());
        },
        branchGetAllByUser: () => {
            return dispatch(branchGetAllByUser());
        },
        changeBranch: (branchid) => {
            return dispatch(changeBranch(branchid));
        },
        changeLanguage: (languageId) => {
            return dispatch(updateLanguageRequest(languageId));
        },
        changeWarehouse: (warehouseId) => {
            return dispatch(changeWarehouse(warehouseId));
        },
    };
};

Branch.propTypes = {
    branchGetAll: PropTypes.func,
    branchGetAllByUser: PropTypes.func,
    changeBranch: PropTypes.func,
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string,
    listBranch: PropTypes.array,
    languages: PropTypes.array,
    currentUser: PropTypes.object,
    userSettings: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
